<template>
  <v-container
   fluid
   class="pl-0 pr-0"
   style="max-width: 450px;"
  >
    <GecomMobile v-if="permiteAcao({ path: '/relatorios/gecom-home/' })" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import { errorSnackbar } from '@/core/service/utils'

// KbnCanvas.share()
export default {
  name: 'Home',

  components: {
    GecomMobile: () => import('@/views/relatorios/gecomMobile')
  },

  data: () => ({
  }),
  
  mounted() {
    // this.getUrlKibana().catch(e => errorSnackbar(e.error))
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('home', ['urlkibana']),
  },

  methods: {
    ...mapActions('home', ['getUrlKibana'])
  }
}
</script>

<style lang="scss" scoped>
  .iframe-kb {
    min-height: calc(100vh - 112px + 24px);
    border: none;
    margin: -24px;
    width: calc(100% + 50px);
  }
</style>